// Modules
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// Components
import Layout from "../components/layout";

// Styles
import * as styles from "./styles.module.scss";

const NotFoundPage = () => (
  <Layout>
    <div className={styles.notFoundContent}>
      <p>Oops...</p>
      <StaticImage
        src="../../assets/images/404.webp"
        alt="not-found"
        placeholder="blurred"
        width={564}
        height={288}
      />
      <h1>Sorry, we couldn't find the page you were looking for</h1>
      <p>
        Get back to the safety of <Link to="/">our homepage</Link> or{" "}
        <Link to="/contact-us">get in touch</Link> please.
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
